import React from 'react';
import styled from 'styled-components';

import { blue, tints, white } from 'styles/colors';
import { Container, FlexWrapper, H1, Image } from 'components';
import CheckmarkIcon from 'assets/icons/checkmark.svg';
import { lgDesktop, mobile, smDesktop, useQuery } from 'styles/breakpoints';

const HELPS_WITH = [
  'High blood pressure',
  'High cholesterol',
  'High blood sugar',
  'Weight management',
];

export const HelpsWith = () => {
  const { isMobile, isTablet, isSmDesktop } = useQuery();

  return (
    <StyledContainer>
      <FlexWrapper
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexWrapper flexDirection={isSmDesktop ? 'column' : 'row'}>
          <ProductImage src="pulsio_box_with_orange_drink" />
          <FlexWrapper flexDirection="column">
            <H1
              fontSize="2.625rem"
              mobileFontSize="2.125rem"
              lineHeight="3.375rem"
              mobileLineHeight="2.75rem"
              color={blue}
            >
              Helps with:
            </H1>
            {HELPS_WITH.map((item, idx) => (
              <StyledHelpItem key={idx}>
                <CheckmarkIcon />
                {item}
              </StyledHelpItem>
            ))}
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
      {isTablet && <SupplementFactsMobile src="supplement_facts_mobile" />}

      {!isTablet && <SupplementFactsDesktop src="supplement_facts" />}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  border: 1px solid ${tints.blue(1)};
  border-radius: 0.625rem;
  background-color: ${white};
  margin-top: 6.625rem;
  padding: 1.875rem 3.75rem;
  position: relative;

  @media ${mobile} {
    margin: 0 1.5rem;
    padding: 0.5rem;
  }
`;

const ProductImage = styled(Image)`
  width: 27.875rem;

  @media ${lgDesktop} {
    width: 20rem;
  }
`;

const StyledHelpItem = styled.div`
  color: ${tints.blue(8)};
  font-family: 'Acumin Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.688rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const SupplementFactsDesktop = styled(Image)`
  position: absolute;
  width: 23.625rem;
  top: -1.563rem;
  right: 3.75rem;
  image-rendering: -webkit-optimize-contrast;

  @media ${lgDesktop} {
    top: -3.563rem;
  }
  @media ${smDesktop} {
    top: 0.563rem;
  }
`;

const SupplementFactsMobile = styled(Image)`
  width: 100%;
  margin-top: 1.563rem;
  image-rendering: -webkit-optimize-contrast;
`;
