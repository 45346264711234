import React from 'react';
import styled from 'styled-components';
import { text, white } from 'styles/colors';

import Background from 'pages/summary-2/elements/Background';
import { Container, FlexWrapper, H1, Image, PrimaryButton } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

interface IRuthStory {
  onClick: () => void;
}

export const RuthStory: React.FC<IRuthStory> = ({ onClick }) => {
  const { isMobile } = useQuery();

  return (
    <Background margin="6.563rem 0 0 0" color={white}>
      <Container padding="5rem 0">
        <FlexWrapper
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {isMobile && (
            <RightSideWrapper>
              <Image src="ruth_before_and_after" />
            </RightSideWrapper>
          )}
          <LeftSideWrapper>
            <H1
              fontSize="2.625rem"
              mobileFontSize="1.75rem"
              mobileLineHeight="2.25rem"
              lineHeight="3.125rem"
              margin="0 0 1.5rem"
            >
              Ruth was so stressed it almost killed her
            </H1>
            <TextBlock>
              Like many Americans - my diet and exercise were not a primary
              concern. I knew I wasn’t living right. My daughter pretty much
              forced me to see a doctor. That’s how I got under the care of
              Alex, who is one of the founders of Pulsio. At first, he got me on
              a strict diet and exercise schedule, but that only reduced my
              hypertension a little - I was not overweight to begin with, so
              that was not my main concern. I was better, but I still sometimes
              felt my symptoms creeping up on me.
            </TextBlock>
            <TextBlock>
              A few months later, I went on a routine visit to Dr. Alex. This
              time he told me that he has been testing a few natural ingredients
              with his patients, and they showed good results so he urged me to
              try it. I got a box of sachets from him. Back then, it didn’t even
              have labels. So I tried the supplement. Apart from it tasting
              great, it also showed promising results - my shortness of breath
              subsided, dizziness became a rarity in my life. It has now been a
              year since I started taking the supplements. It is now a part of
              my daily routine. I thought my hypertension would be with me for
              the rest of my life, but it looks like I finally have it under
              control.
            </TextBlock>
            <TextBlock>Ruth, Atlanta</TextBlock>
          </LeftSideWrapper>
          {!isMobile && (
            <RightSideWrapper>
              <Image src="ruth_before_and_after" />
            </RightSideWrapper>
          )}
        </FlexWrapper>
        <FlexWrapper width="100%" justifyContent="center">
          <PrimaryButton
            maxWidth={isMobile ? '100%' : 'auto'}
            margin="1.5rem 0 1.5rem"
            onClick={onClick}
          >
            JOIN RUTH AND 74,000 OTHERS!
          </PrimaryButton>
        </FlexWrapper>
      </Container>
    </Background>
  );
};

const LeftSideWrapper = styled.div`
  max-width: 28.75rem;
  width: 100%;
  margin-right: 6.125rem;

  @media ${mobile} {
    max-width: 100%;
    margin-right: 0;
  }
`;

const RightSideWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBlock = styled.div`
  font-size: 1rem;
  color: ${text};
  font-family: 'Acumin Pro', sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
